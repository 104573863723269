// extracted by mini-css-extract-plugin
export var card = "WorkshopCard-module--card--QlyB6";
export var category = "WorkshopCard-module--category--d9zwu";
export var categoryWhite = "WorkshopCard-module--category-white--DE3-W";
export var date = "WorkshopCard-module--date--XQMCo";
export var dateTime = "WorkshopCard-module--date-time--OQF6t";
export var descriptionBlock = "WorkshopCard-module--description-block--9qP+A";
export var image = "WorkshopCard-module--image--bID+O";
export var instructorBlock = "WorkshopCard-module--instructor-block--yedeP";
export var instructorPhoto = "WorkshopCard-module--instructor-photo--KPSLk";
export var recording = "WorkshopCard-module--recording--TS6uP";
export var secondary = "WorkshopCard-module--secondary--5JhZM";
export var superTitle = "WorkshopCard-module--super-title--Mdc9y";
export var ticketLink = "WorkshopCard-module--ticket-link--89QV5";
export var timeAddress = "WorkshopCard-module--time-address--emvS1";
export var title = "WorkshopCard-module--title--mzCFy";